// React
import React from 'react'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Utils
import { copyTextToClipboard } from '../utils/copyTextToClipboard'

// Share component
const Share = ({
    title: pageTitle,
    description: pageDescription,
    url: siteUrl,
    pathname: pagePath,
    thumbnail: pageThumbnail,
}) => {

    // Construct the URL for this page
    const shareUrl = siteUrl + pagePath;
    const shareThumbnail = siteUrl + pageThumbnail;

    // Twitter
    const twitter =
        `https://twitter.com/intent/tweet?url=${shareUrl}&text=${pageTitle} by @GameDevMix #GameDev`;

    // Facebook
    const fb =
        `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
    // u=MyPageUrl
    // &summary=MySummary
    // &title=MyTitle
    // &description=MyDescription
    // &picture=MyYmageUrl

    // Pinterest
    const pinterest = `http://pinterest.com/pin/create/link/?url=${shareUrl}&media=${shareThumbnail}&title=${pageTitle}&description=${pageTitle+ ' | ' + pageDescription}`

    // Email
    const email = `mailto:${''}?subject=${pageTitle}&body=${pageTitle + ': ' + shareUrl}`

    // Render
    return (
        <div className="share">

            <h3>
                Sharing is caring
            </h3>

            <p>
                Like what you've read? Why not share it with others?
            </p>

            <ul className="social">

                <li>
                    <a href={fb} target="blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={["fab", "facebook"]} />
                        Facebook
                    </a>
                </li>

                <li>
                    <a href={twitter} target="blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={["fab", "twitter"]} />
                        Twitter
                    </a>
                </li>

                <li>
                    <a href={pinterest} target="blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={["fab", "pinterest"]} />
                        Pinterest
                    </a>
                </li>

                <li>
                    <a href={email} target="blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={["fas", "envelope"]} />
                        Email
                    </a>
                </li>

                <li>
                    <div className="copy-clipboard" onClick={ () => copyTextToClipboard(shareUrl)}>
                        <FontAwesomeIcon icon={["fas", "paste"]} />
                        Clipboard
                    </div>
                </li>

            </ul>

        </div>
    )
}

export default Share;