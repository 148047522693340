// React
import React from 'react'

// Gatsby
import { 
    Link
} from "gatsby"

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// PreNext component
const PrevNext = (props) => {

    // Destructure props
    const { 
        previous,
        next
    } = props

    // Render
    return (
        <nav className="prevnext">

            <ul>

                {previous && (
                    <li className="previous">
                        <Link to={previous.fields.slug} rel="prev">

                            <div className="arrow">
                                <FontAwesomeIcon icon={["fas", "caret-left"]} />
                            </div>

                            <div className="content">
                                <div className="category">
                                    {previous.frontmatter.category}  
                                </div>                                
                                <div className="title">
                                    {previous.frontmatter.title}
                                </div>
                                <div className="desc">
                                    {previous.frontmatter.description}
                                </div>
                            </div>

                        </Link>
                    </li>
                )}

                { next && (
                    <li className="next">
                        <Link to={next.fields.slug} rel="next">
                            
                            <div className="content">
                                <div className="category">
                                    {next.frontmatter.category}  
                                </div>
                                <div className="title">
                                    {next.frontmatter.title}  
                                </div>
                                <div className="desc">
                                    {next.frontmatter.description}
                                </div>
                            </div>                                

                            <div className="arrow">
                                <FontAwesomeIcon icon={["fas", "caret-right"]} />
                            </div>

                        </Link>
                    </li>
                )}

            </ul>

        </nav>
    )
}

export default PrevNext;