// React
import React from 'react'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Discuss component
const Discuss = ({
    pathname: pagePath,
    url: siteUrl,
}) => {

    // Construct the URL for this page
    const shareUrl = siteUrl + pagePath;

    // Twitter
    const twitterDiscussUrl = 
        `https://mobile.twitter.com/search?q=${shareUrl}`;

    // Render
    return (
        <div className="discuss">

            <h3>
                Comments
            </h3>

            <p>
                Got some thoughts on this topic? Let's talk about it!
            </p>

            <a href={twitterDiscussUrl} target="blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={["fab", "twitter"]} />
                Join the Discussion
            </a>

        </div>
    )
}

export default Discuss;