// React
import React from 'react';

// Gatsby
import {
    Link,
    graphql
} from "gatsby"

// Image
import Image from "gatsby-image"

// MDX
import MdxWrapper from "../components/mdx/MdxWrapper"

// Site components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Page components
import Discuss from '../components/discuss';
import Share from '../components/share';
import PrevNext from "../components/prevnext";
import TableOfContents from '../components/TableOfContents';

// Styles
import "../styles/pages/BlogPostPage.scss"
import "katex/dist/katex.min.css"

// Component for a single blog post
const BlogPostTemplate = ({
    data,
    pageContext,
    location
}) => {

    // Website data
    const siteUrl = data.site.siteMetadata.siteUrl;

    // The previous and next posts' pages
    const { 
        previous,
        next
    } = pageContext

    // MDX data for this post
    const postData = data.mdx;

    // Frontmatter for this post
    const {
        title: postTitle,
        category: postCategory,
        date: postDate,
        tags: postTags,
        description: postDescription,
    } = postData.frontmatter;

    // Post thumbnail
    let postThumbnail = postData?.frontmatter.image?.childImageSharp?.fluid;
    let shareThumbnail = postData?.frontmatter.image?.childImageSharp?.resize?.src;

    // Post headings
    const postHeadings = postData?.headings;

    // Render
    return (
        <Layout>

            {/* SEO */}
            <SEO
                title={postTitle}
                description={postDescription}
                pathname={location.pathname}
                thumbnail={shareThumbnail}
            />

            {/* Page */}
            <article id="blog-post-page">

                {/* Page header */}
                <header className="page-header page-header-with-thumbnail">

                    {/* Text on lhs */}
                    <section className="header-text">

                        {/* Page category */}
                        <div className="page-category">
                            {postCategory}
                        </div>
                        
                        {/* Page title */}
                        <h1 className="page-title">
                            {postTitle}
                        </h1>

                        {/* Page description */}
                        <p className="page-description">
                            {postDescription || postData.excerpt}
                        </p>

                        {/* Page meta */}
                        <div className="page-meta">

                            <div className="date">
                                {postDate} &middot; by Ben from GameDevMix
                            </div>

                            <div className="tags">
                                {postTags.map((tag, index) => {
                                    return (
                                        <Link to={`/tags/${tag}`} key={tag}>
                                            {tag}
                                        </Link>
                                    )
                                })
                                }
                            </div>
                        </div>

                    </section>

                    {/* Image on rhs */}
                    <section className="page-image">
                        {postThumbnail &&
                            <Image fluid={postThumbnail} />
                        }
                    </section>

                </header>

                {/* Page main */}
                <main className="page-main">

                    {/* Page body */}
                    <section className="page-body">

                        {/* MDX */}
                        <MdxWrapper>
                            {postData.body}
                        </MdxWrapper>

                    </section>

                    {/* Table of contents */}
                    {postHeadings &&
                        <TableOfContents 
                            className="page-toc"
                            title={postTitle}
                            headings={postHeadings}
                            pathname={location.pathname}
                        />
                    }

                </main>

                {/* Page footer */}
                <footer className="page-footer">

                    {/* Share */}
                    <Share
                        title={postTitle}
                        description={postDescription}
                        url={siteUrl}
                        pathname={location.pathname}
                        thumbnail={shareThumbnail}
                    />

                    {/* Discuss */}
                    <Discuss
                        url={siteUrl}
                        pathname={location.pathname}
                    />

                    {/* The prev/next links */}
                    <PrevNext
                        previous={previous}
                        next={next}
                    />

                </footer>

            </article>

        </Layout>
    )
}

// Export component
export default BlogPostTemplate

// GraphQL query
export const pageQuery = graphql`

  query BlogPostBySlug($slug: String!) {

    site {
      siteMetadata {
        siteUrl
      }
    }

    mdx(
        fields: { slug: { eq: $slug } }
    ) {
        excerpt(pruneLength: 160)
        body
        timeToRead
        frontmatter {
            title
            category
            date(formatString: "MMM D, YYYY")
            description
            tags
            #image {
                # childImageSharp {
                    #   resize(width: 1200, height: 630) {
                        #   src
                        #}              
                        #fluid(maxWidth: 800) {
                            #...GatsbyImageSharpFluid
                            #}
                            #}
                            #}        
        }
        headings {
            depth,
            value
        }        
    }    
  }
`
